var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("h3", [_vm._v("\n    Registered Agent Service\n  ")]),
      _vm.activeRAServices
        ? _c("multi-select", {
            attrs: {
              value: _vm.cartProductsByKind(_vm.productKind),
              "track-by": "id",
              multiple: true,
              label: "jurisdiction",
              placeholder: "Select jurisdictions for registered agent service",
              options: _vm.productsSorted,
            },
            on: {
              select: function ($event) {
                return _vm.addRAToCart({
                  ...$event,
                  productKind: _vm.productKind,
                })
              },
              remove: function ($event) {
                return _vm.removeFromCart({ id: $event.cartItemId })
              },
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "singleLabel",
                  fn: function ({ option }) {
                    return [
                      _vm._v(
                        "\n      " + _vm._s(option.jurisdiction) + "\n    "
                      ),
                    ]
                  },
                },
              ],
              null,
              false,
              4271882701
            ),
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "mt-2" },
        [
          _vm.showLawOnCall
            ? _c(
                "b-form-checkbox",
                {
                  attrs: { size: "md" },
                  model: {
                    value: _vm.lawOnCallServiceEnabled,
                    callback: function ($$v) {
                      _vm.lawOnCallServiceEnabled = $$v
                    },
                    expression: "lawOnCallServiceEnabled",
                  },
                },
                [
                  _vm._v(
                    "\n      Include law on call service - Free for the first 60 days\n    "
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }