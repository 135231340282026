<template>
  <div>
    <h3>
      Registered Agent Service
    </h3>

    <multi-select
      v-if="activeRAServices"
      :value="cartProductsByKind(productKind)"
      track-by="id"
      :multiple="true"
      label="jurisdiction"
      placeholder="Select jurisdictions for registered agent service"
      :options="productsSorted"
      @select="addRAToCart({ ...$event, productKind })"
      @remove="removeFromCart({ id: $event.cartItemId })"
    >
      <template slot="singleLabel" slot-scope="{ option }">
        {{ option.jurisdiction }}
      </template>
    </multi-select>

    <div class="mt-2">
      <b-form-checkbox v-if="showLawOnCall" v-model="lawOnCallServiceEnabled" size="md">
        Include law on call service - Free for the first 60 days
      </b-form-checkbox>
    </div>
  </div>
</template>

<script>
  import MultiSelect from 'vue-multiselect'
  import { sortBy } from 'lodash/collection'
  import { mapActions, mapGetters } from 'vuex'

  export default {
    name: 'RegisteredAgentCheckout',

    components: {
      MultiSelect,
    },
    props: {
      products: Array,
      company: Object,
    },
    data() {
      return {
        productKind: 'registered_agent_product',
        selectedProducts: [],
      }
    },
    computed: {
      ...mapGetters('checkout', [
        'cartProductsByKind',
        'optionalItemEnabled',
        'findProductBy',
      ]),
      ...mapGetters('companies', [
        'activeRAServices',
      ]),
      ...mapGetters('lawOnCall', ['offerLawOnCall']),
      routeParams() {
        return this.$store.state.appRouter.params
      },
      productsSorted() {
        if (this.activeRAServices.length) {
          return sortBy(this.products, 'jurisdiction').filter(p => !this.activeRAServices.map(s => s.jurisdiction.state_province_region).includes(p.jurisdiction))
        } else {
          return sortBy(this.products, 'jurisdiction')
        }
      },
      lawOnCallServiceEnabled: {
        get() {
          return this.optionalItemEnabled('lawOnCall')
        },
        set() {
          this.toggleOptionalItem({ name: 'lawOnCall' })
        },
      },
      showLawOnCall() {
        return this.findProductBy('simple_product', { category: 'law-on-call' }) &&
          this.offerLawOnCall
      },
    },
    async mounted() {
      if (this.company.entity_type === 'Attorney for Bar' && this.complianceServiceEnabled) {
        this.complianceServiceEnabled = false
      }

      await this.loadActiveRAServices({ id: this.routeParams.companyId })
      await this.resolveOptionalItems({})
      await this.determineLawOnCallTrialEligibility(this.routeParams.companyId)
    },
    methods: {
      ...mapActions('checkout', [
        'addToCart',
        'removeFromCart',
        'setupContext',
        'resolveOptionalItems',
        'toggleOptionalItem',
      ]),
      ...mapActions('companies', ['loadActiveRAServices']),
      ...mapActions('lawOnCall', [
        'determineLawOnCallTrialEligibility',
      ]),
      addRAToCart(product) {
        this.addToCart(product)
        this.$emit('product-added', product)
      },
    },
  }
</script>
<style lang="scss">
.multiselect {
  margin-top: 1.5em !important;
}
.multiselect__tags {
  border: 1px solid black; //TODO: This text should be teal after design team stuff
}
</style>
